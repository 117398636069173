/* eslint no-param-reassign: 0 */
module.exports = function () {
    const buttonStyles = new CSSStyleSheet();
    const selectStyles = new CSSStyleSheet();
    const ogSelectFrequency = document.querySelector('og-select-frequency');
    const ogOptoutButton = document.querySelector('og-optout-button');
    const ogOptinButton = document.querySelector('og-optin-button');

    selectStyles.replaceSync(`
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 13px;
            -webkit-box-shadow: inset 0 -1px 0 #ddd;
            box-shadow: inset 0 -1px 0 #ddd;
            padding: 0.5rem 1rem 0.5rem 0;
            text-overflow: ellipsis;
            font-size: 0.8125rem;
            outline: none;
            outline-width: 0 !important;
            font-weight: 400;
            line-height: 1.4;
            color: #393939;
            vertical-align: middle;
            background: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) no-repeat right 0rem center/8px 10px;
            background-color: transparent;
            border: 0rem solid transparent;
            border-radius: 0;
            display: inline-block;
            width: 100%;
            height: calc(1.4em + 1rem + 0rem);
            -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        }

        select:focus {
            -webkit-box-shadow: inset 0 -2px 0 #097fb3;
            box-shadow: inset 0 -2px 0 #097fb3;
        }
    `);
    buttonStyles.replaceSync(`
        button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 1.4em;
            height: 1.4em;
            border-radius: 100%;
            margin-right: 0.5rem;
            outline: none;
            outline-width: 0 !important;
            position: relative;
        }

        button.active {
            border-color: #3b87fd;
        }

        button.active::after {
            content: " ";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 100%;
            background: #fffff;
            border-width: 4px;
            border-style: solid;
            border-color: #3b87fd;
            border-image: initial;
        }
    `);

    if (ogOptoutButton) {
        ogOptoutButton.shadowRoot.adoptedStyleSheets = [buttonStyles];
        ogOptinButton.shadowRoot.adoptedStyleSheets = [buttonStyles];
    }

    if (ogSelectFrequency) {
        const ogSelect = ogSelectFrequency.renderRoot.querySelector('og-select');
        if (ogSelect) {
            ogSelect.shadowRoot.adoptedStyleSheets = [selectStyles];
        }
    }

    const observer = new MutationObserver(function (mutations) {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                const ogOptoutButtonMutation = $(mutation.addedNodes).find('og-optout-button');
                if (ogOptoutButtonMutation && ogOptoutButtonMutation.length > 0) {
                    ogOptoutButtonMutation.toArray().forEach(button => {
                        button.shadowRoot.adoptedStyleSheets = [buttonStyles];
                    });
                }
                const ogOptinButtonMutation = $(mutation.addedNodes).find('og-optin-button');
                if (ogOptinButtonMutation && ogOptinButtonMutation.length > 0) {
                    ogOptinButtonMutation.toArray().forEach(button => {
                        button.shadowRoot.adoptedStyleSheets = [buttonStyles];
                    });
                }
                const ogSelectFrequencyMutation = $(mutation.addedNodes).find('og-select-frequency');
                if (ogSelectFrequencyMutation && ogSelectFrequencyMutation.length > 0) {
                    ogSelectFrequencyMutation.each(function () {
                        const frequency = $(this);
                        setTimeout(function () {
                            const select = frequency.get(0).renderRoot.querySelector('og-select');
                            if (select) {
                                select.shadowRoot.adoptedStyleSheets = [selectStyles];
                            }
                        }, 0);
                    });
                }
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
}
;
