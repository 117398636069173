// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
const processInclude = require('base/util');

require('./components/oneTrust');
require('ditto/main');

try {
    processInclude(require('./components/subscription'));
} catch (err) {
    // plugin not in use
}
