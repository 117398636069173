dataLayer = dataLayer || [];

const productImpressions = [];
let scrollTimeout;

const isVisible = function ($el) {
    const elementTop = $el.offset().top;
    const elementBottom = elementTop + $el.outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

const trackImpressions = function () {
    const impressions = [];
    $('.product').each(function () {
        const pid = $(this).data('pid');
        if (pid && isVisible($(this)) && !productImpressions.includes(pid)) {
            productImpressions.push(pid);
            impressions.push({
                id: pid
            });
        }
    });

    if (impressions.length) {
        dataLayer.push({
            ecommerce: {
                impressions
            }
        });
    }
};

const onScrollFinished = function () {
    clearTimeout(scrollTimeout);
    trackImpressions();
};

module.exports = function () {
    $(document).on('scroll', function () {
        scrollTimeout = setTimeout(onScrollFinished, 500);
    });

    $('body').on('click', '.product a', function (e) {
        const url = $(this).attr('href');
        const id = $(this).data('pid');

        dataLayer.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    products: [{
                        id: id
                    }]
                }
            }
        });
    });

    trackImpressions();
};
